.person-details {
    &.persona-display-avatar {
        font-size: 10px;
        font-weight: 600;
        line-height: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 8px;
    }

    &.persona-display-name {
        display: block;
        width: 210px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.persona-principal-name {
        display: block;
        width: 210px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

}