.sidebar {
    &__menu {
        list-style: none;
        padding: var(--spacingVerticalXXXL) var(--spacingHorizontalXXL);
        color: var(--colorNeutralBackground5);

        &--shrinked {
            padding-left: var(--spacingHorizontalM);
            padding-right: var(--spacingHorizontalM);

            & .sidebar__menu-item-icon {
                margin: 0;
            }

            & .sidebar__menu-item-text {
                display: none;
            }

            & .sidebar__submenu {
                display: none;
            }

            & .sidebar__menu-item-expand {
                display: none !important;
            }
        }
    }

    &__menu-item {
        margin: var(--spacingVerticalS) 0;
        user-select: none;

        &-inner {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: var(--spacingVerticalXS) var(--spacingHorizontalXS);
            font-size: 16px;
            color: var(--colorNeutralBackground5);
            text-decoration: none;


            &:hover {
                background-color: var(--colorNeutralForeground4);
                border-radius: var(--borderRadiusMedium);
            }
        }

        &-text {
            flex: 1;
        }

        &-expand {
            font-size: 16px;

            &--opened {
                display: none !important;
            }
        }

        &--active .sidebar__menu-item-inner {
            background-color: var(--colorNeutralForeground4);
            border-radius: var(--borderRadiusMedium);
            font-weight: var(--fontWeightSemibold);
        }

        &--active .sidebar__menu-item-icon--filled {
            display: inline !important;
        }

        &--active .sidebar__menu-item-icon--regular {
            display: none;
        }

        &--active .sidebar__submenu {
            display: block;
        }

        &--active .sidebar__menu-item-expand--opened {
            display: inline !important;
        }

        &--active .sidebar__menu-item-expand--closed {
            display: none !important;
        }

    }

    &__menu-item-icon {
        margin-right: var(--spacingHorizontalS);
        font-size: 28px;

        &--filled {
            display: none !important;
        }
    }

    &__submenu {
        margin: var(--spacingVerticalS) 0;
        font-size: 16px;
        list-style: none;
        padding-left: 26px;
        display: none;

        &-item {
            margin: var(--spacingVerticalXS) 0;

            &-inner {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                color: var(--colorNeutralBackground5);
                text-decoration: none;

                &:hover {
                    color: var(--colorNeutralForeground4);
                    font-weight: var(--fontWeightSemibold);
                }
            }

            &-icon {
                font-size: 8px;
                margin-right: var(--spacingHorizontalSNudge);
            }

        }
    }
}