.exams-wrapper {
    padding: var(--spacingVerticalXXL) var(--spacingHorizontalXXXL);
    //padding-right: var(--spacingHorizontalSNudge);
    padding-top: 0;

    &__breadcrumbs-wrapper {
        padding-top: var(--spacingVerticalXXXL);
        padding-bottom: var(--spacingVerticalL);
    }
}

.exams-table {
    &__wrapper {
        border-radius: var(--borderRadiusLarge);
        box-shadow: 0px 0px 4px var(--colorNeutralShadowAmbient);
        overflow: hidden;
        border: 1px solid var(--colorNeutralBackground5);
        border-bottom-width: 0px;
    }

    &__cell {
        &--small {
            font-size: var(--fontSizeBase200);
            color: var(--colorNeutralForeground1);
        }

        &--center {
            text-align: center;
        }

        &--center button {
            justify-content: center;
        }

        &--bold,
        &--bold button {
            font-weight: var(--fontWeightSemibold);
        }
    }
}

.table-row-selected {
    background-color: var(--colorNeutralBackground1Selected);

   
}