.parameter-cell {   
    vertical-align: top !important;
    padding-top: var(--spacingVerticalSNudge) !important;
    padding-bottom: var(--spacingVerticalSNudge) !important;
    font-size: var(--fontSizeBase200);
    height: auto !important;
}

.input-wrapper {
    display: flex;
    flex-direction: column;

    &--error {
        color: var(--colorPaletteRedForeground1);
    }
}

.chevron {
    &--button {
        width: 20px;
        height: 16px;
        padding: 0 !important;

        & span {
            font-size: 13px;
            margin: 0px 4px;
            height: 16px;
            line-height: 16px;
            text-align: center;
        }
    }

    
}