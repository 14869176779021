.pagination{
    display: flex;
    padding-left: 0;
    list-style: none;
   // border-radius: .25rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    list-style: none;
}
  
  .page-link {
    position: relative;
    display: block;
    padding: 5px 10px;
    margin-left: 1px;
    line-height:1.25;
    color: rgb(50, 49, 48);
    background-color: #fff;
    border: 1px solid #dee2e6;
  
    &:hover {
      color:  white;
      text-decoration: none;
      background-color:rgb(3, 120, 124);;
      border-color:rgb(3, 120, 124);;
    }
  
    &:focus {
      z-index: 2;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    }
  
    // Opinionated: add "hand" cursor to non-disabled .page-link elements
    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }
  }
  
  .page-item {
    &:first-child {
      .page-link {
        margin-left: 0;
      // border-radius: .25rem;
      }
    }
   
  
    &.active .page-link {
      z-index: 1;
      color: #fff;
      background-color: rgb(3, 120, 124);;
      border-color:rgb(3, 120, 124);;
    }
  
    &.disabled .page-link {
        color: #6c757d;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }
  }

  .filter-group{
    display: flex;
     
    .filter-option{
      display: flex;
      flex-direction: column;
      margin: 5px 10px;
    }
  }