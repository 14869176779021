html {
  height: 100%;
  /* Fallback CSS for IE 4-6 and older browsers. Note: Without this setting, body below cannot achieve 100% height. */
  height: 100vh;
  /* Overrides 100% height in modern HTML5 browsers and uses the viewport's height. Only works in modern HTML5 browsers */
}

body {
  height: auto;
  /* Allows content to grow beyond the page without overflow */
  width: auto;
  /* Allows content to grow beyond the page without overflow */
  min-height: 100%;
  /* Starts web page with 100% height. Fallback for IE 4-6 and older browsers */
  min-height: 100vh;
  /* Starts web page with 100% height. Uses the viewport's height. Only works in modern HTML5 browsers */
  margin: 0;
  padding: 0;
}

div#root .fui-FluentProvider {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.dialog-content-wrapper {
  padding-top: var(--spacingVerticalXXL);
  padding-bottom: var(--spacingVerticalXXL);
}

.dialog {
  &--content-with-spacing {
    padding-top: var(--spacingVerticalXXL) !important;
    padding-bottom: var(--spacingVerticalXXL) !important;
  }
}

.input-field {
  &--full-width {
    display: flex !important;
    flex-direction: column;
    width: 100%;

    &>span {
      width: 100%;
    }
  }

  &--streched {
    width: 100%;

    &>input {
      width: 100%;
    }

    &>span {
      width: fit-content;
    }
  }
}




.input-form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: var(--spacingVerticalXL);

  &-item {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    align-items: center;

    //  align-items: center;
    &--without-margin {
      margin: 0;
    }
  }

  &-checkbox {
    &>div>span {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    & .fui-Checkbox__indicator {
      margin-left: 0;
    }
  }

  &-label {
    font-weight: 600;
    width: 30%;
    text-align: right;
    max-width: 250px;
    padding-right: 22px;
    padding-top: 5px;
    padding-bottom: 5px;


    &-center {
      text-align: center;
      font-weight: bold;
    }

    &-sm {
      max-width: 150px;
    }

    &-xs {
      max-width: 100px;
    }
  }

  &-field {
    display: grid;
    max-width: 300px;
    width: 100%;

    &>span {
      width: 100%;
    }
  }
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.s365-table {
  &__wrapper {
    border-radius: var(--borderRadiusLarge);
    box-shadow: 0px 0px 4px var(--colorNeutralShadowAmbient);
    //overflow: hidden; // TODO: Fix this to get border radius working
    border: 1px solid var(--colorNeutralBackground5);
    border-bottom-width: 0px;
  }

  &__cell {
    height: 100% !important;

    &--small {
      font-size: var(--fontSizeBase200);
      color: var(--colorNeutralForeground1);
    }

    &--center {
      text-align: center;
    }

    &--center-vertical {
      height: 100% !important;
      vertical-align: middle !important;
    }


    &--center button {
      justify-content: center;
    }

    &--center div {
      justify-content: center;
    }

    &--bold,
    &--bold button {
      font-weight: var(--fontWeightSemibold);
    }
  }
}

.column {
  &--center {
    text-align: center;
  }

  &--center button {
    justify-content: center;
  }

  &--center div {
    justify-content: center;
  }
}



.max-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;

}

.alert--info-global {
  background-color: var(--colorPaletteBlueBackground2) !important;
}

.alert--error-small {
  min-height: 30px !important;
}

.tab-content {
  width: 100%;
}

.tab-with-errors {
  &>span {
    color: red !important;
  }
}

.disable-visual-search {
  pointer-events: none;
}