.edit-application {
    & .containter {
        display: flex;
        flex-direction: column;
    }

    & .header {
        display: flex;
        padding-left: 24px;
        align-items: center;
        margin-right: 12px;
        margin-bottom: 15px;
    }

    & .input-form {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 20px;
        margin-top: 20px;

        &-item {
            display: flex;
            width: 100%;
            margin-bottom: 15px;
            & .fui-Field {
                flex: 1;

                &>span {
                    width: 100%;
    
                    &>input {
                        width: 100%;
                    }
                }
            }
        }
    }

    & .help-block {
        display: block;
        margin-top: 5px;
        margin-bottom: 10px;
        color: #737373;
    }

    & .input-label {
        font-weight: 600;
        width: 30%;
        text-align: right;
        max-width: 250px;
        padding-right: 22px;

        &-required {
            padding-right: 0;
        }

    }



    & .input-field {
        display: flex;
        flex-direction: column;
           width: 100%;
        max-width: 350px;

        &-small {
            width: 60%;
            max-width: 100px;
        }
        &>span {
            width: 100%;

            &>input {
                width: 100%;
            }
        }
    }


}