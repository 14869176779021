.grant-access-callout {
    width: 320px;

    & .header {
        display: flex;
        flex-direction: row;
        // background-color: var(--colorNeutralBackground2);

        & .title {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            & .main {
                font-size: var(--fontSizeBase400);
                font-weight: var(--fontWeightMedium);
            }

            & .sub {
                font-size: var(--fontSizeBase200);
            }
        }
    }

    & .callout-body {
        padding: var(--spacingVerticalL);
    }

}