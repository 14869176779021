.files-wrapper {
    padding: var(--spacingVerticalXXL) var(--spacingHorizontalXXXL);
    //padding-right: var(--spacingHorizontalSNudge);
    padding-top: 0;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 48px - 1px - var(--spacingVerticalXXL)); // 100vh - topBarHeight - topBarBottomBorder - 
    overflow-y: auto;

    &__breadcrumbs-wrapper {
        padding-top: var(--spacingVerticalXXXL);
        padding-bottom: var(--spacingVerticalL);
    }
}

.page-wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;

    &--dragenter {
        background-color: aliceblue;

        & .page-wrapper__dropzone {
            display: flex;
        }
    }

    &__dropzone {
        display: none; // Switch to flex on drag enter
        align-items: center;
        justify-content: center;
        color: var(--colorNeutralForegroundInverted);
        font-size: var(--fontSizeBase600);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        background-color: var(--colorScrollbarOverlay);
        border: 5px dashed var(--colorBrandForegroundOnLight);
    }
}



.side-panel {
    min-width: 270px;
    max-width: 270px;
    min-height: calc(100vh - 48px - 1px - var(--spacingVerticalXXL));
    background-color: var(--colorNeutralBackground2);
    padding: 10px;
    border-left: 1px solid var(--colorNeutralBackground5);

    & .header {
        display: flex;
        justify-content: space-between;

        & .file {
            display: flex;
            align-items: center;
            overflow: hidden;

            & .icon {
                margin-left: var(--spacingHorizontalSNudge);
                margin-right: var(--spacingHorizontalSNudge);
            }

            & .title {
                text-overflow: ellipsis;
                overflow: hidden;
                display: inline-block;
                white-space: nowrap;
                font-weight: bold;
            }
        }

        & .close-btn {
            min-width: 0;
        }
    }

    & .panel-item {
        padding: var(--spacingVerticalMNudge) var(--spacingHorizontalMNudge);
        display: flex;
        flex-direction: column;

        & .tags {
            position: relative;
        }
    }

}

.toolbar__wrapper {
    padding: var(--spacingVerticalMNudge) 0;
    border-bottom: 1px solid var(--colorNeutralStencil1);
}






.files-empty-directory {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;

    &__image {
        opacity: 0.5;
    }

    &__text {
        &--main {
            font-size: var(--fontSizeBase500);
            font-weight: var(--fontWeightSemibold);
            padding-top: var(--spacingVerticalXL);
        }

        &--sub {
            margin-top: var(--spacingVerticalXS);
        }
    }
}