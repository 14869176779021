.breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: var(--fontSizeBase500);
    line-height: var(--lineHeightBase500);
    font-weight: var(--fontWeightRegular);

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: var(--spacingVerticalXS) var(--spacingHorizontalXS);

        &--clickable {
            cursor: pointer;

            &:hover {
                background-color: var(--colorNeutralBackground3Hover);
            }
        }

        &--active {
            font-weight: var(--fontWeightSemibold);
        }
    }

    &__chevron {
        margin-top: var(--spacingVerticalXS);

        &:last-child {
            display: none;
        }
    }
}