.filters-wrapper {
    width: 100%;
    padding: var(--spacingVerticalXXL) var(--spacingHorizontalXXXL);
    //padding-right: var(--spacingHorizontalSNudge);
    padding-top: 0;

    &__breadcrumbs-wrapper {
        padding-top: var(--spacingVerticalXXXL);
        padding-bottom: var(--spacingVerticalL);
    }
}

.name-field {
    &--wrapper {
        display: flex;
        flex-direction: column;
    }

    &--input {
        max-width: 300px;
    }

    &--error {
        color: red;
    }
}

.thumbnail {
    &--wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.tab-content {
    margin-top: 20px;
    margin-bottom: 20px;
}

.has-errors {
    &>span {
        color: var(--colorPaletteRedForeground1) !important;
    }
}