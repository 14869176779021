.files-table {
    &__wrapper {
        border-radius: var(--borderRadiusLarge);
        box-shadow: 0px 0px 4px var(--colorNeutralShadowAmbient);
        //overflow: hidden; // TODO: Fix this to get border radius working
        border: 1px solid var(--colorNeutralBackground5);
        border-bottom-width: 0px;
    }

    &__cell {
        &--small {
            font-size: var(--fontSizeBase200);
            color: var(--colorNeutralForeground1);
        }

        &--center {
            text-align: center;
        }

        &--center button, 
        &--center div {
            justify-content: center;
        }
       


        &--bold,
        &--bold button,
        &--bold div {
            font-weight: var(--fontWeightSemibold);
        }
    }
}

.table-file-name {
    display: flex;
    width: fit-content;
    flex-direction: row;
    align-items: center;
    min-width: 100px;
    word-break: break-all;

    &-without-hover {}

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.table-row:hover {
    & .dots-button {
        display: block !important;
    }
}

.table-row-selected {
    background-color: var(--colorNeutralBackground1Selected);

    & .dots-button {
        display: block !important;
    }

    & .table-file-name {
        font-weight: var(--fontWeightSemibold);

    }
}



.dots-button {
    display: none !important;

    &:hover {
        color: blue;
    }
}