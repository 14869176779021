.image-container {
  position: relative;
  display: inline-block;
  width: 100%; /* Adjust the width as needed */  
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
}

.watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-35deg);
  font-size: 100px;
  color: lightgray; /* Light gray color */
  font-weight: bold;
  pointer-events: none; /* Ensure watermark is not clickable */
  white-space: nowrap; /* Prevent text from wrapping */
}