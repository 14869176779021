.filter-param-text>div {
    flex-direction: row !important;
}

.filter-param-text>div>span {
    margin-right: 5px !important;
    display: inline-flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.filter-parameter-select {
    margin-right: 10px;

    & select {
        min-width: 150px;
        padding-right: 25px;
    }
}