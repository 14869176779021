.filters-wrapper {
    padding: var(--spacingVerticalXXL) var(--spacingHorizontalXXXL);
    //padding-right: var(--spacingHorizontalSNudge);
    padding-top: 0;

    &__breadcrumbs-wrapper {
        padding-top: var(--spacingVerticalXXXL);
        padding-bottom: var(--spacingVerticalL);
    }
}

.group-label {
    font-weight: var(--fontWeightSemibold);
    margin-right: 10px;
}

.filter-name {
    &--group {
        display: flex;
    } 

    // &--value {
    //    // font-weight: var(--fontWeightBold);
    // }

}

.thumbnail {
    &--wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.tab-content {
    margin-top: 20px;
    margin-bottom: 20px;
}