.parameter-picker-next {
    &__sugested-item {       
        text-align: left;
        font-size: 14px;
        //padding: 3px;
        
        &--error{
            display: flex;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            color: var(--colorPaletteRedForeground1);
        }
    }
}