.edit-agent-group {


    & .input-form {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 20px;
        margin-top: 20px;

        &-item {
            display: flex;
            width: 100%;
            margin-bottom: 15px;
            align-items: center;
            & .fui-Field {
                flex: 1;
            }
        }
    }

    & .help-block {
        display: block;
        margin-top: 5px;
        margin-bottom: 10px;
        color: #737373;
    }

    & .input-label {
        font-weight: 600;
        width: 30%;
        text-align: right;
        max-width: 250px;
        padding-right: 22px;

        &-required {
            padding-right: 0;
        }

    }

    & .input-field {      
        max-width: 350px;

        &-licensing-server{
            display: flex;
            flex-direction: column;
            width: 60%;
        }
        &-full-width {
            width: 100%;
            max-width: unset;

            &>span {
                width: 100%;

                &>input {
                    width: 100%;
                }
            }
        }

        &-radio {
            flex-direction: row;

            & .fui-Radio {
                display: flex;
                align-items: center;
            }
        }

        &-small {
            width: 60%;
            max-width: 350px;
        }
    }





}