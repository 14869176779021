.editable-text-area-field {
    &-readonly {
        &:hover {
            cursor: not-allowed;
        }

        &>span {
            width: 100%;
            pointer-events: none;


            &>input {
                width: 100%;
            }

            &>textarea {
                width: 100%;
                height: 100%;
            }
        }

    }

    &>span {
        width: 100%;


        &>input {
            width: 100%;
        }

        &>textarea {
            width: 100%;
            height: 100%;
        }
    }
}