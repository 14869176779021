.content-wrapper {
    padding: var(--spacingVerticalXXL) var(--spacingHorizontalXXXL);
    //padding-right: var(--spacingHorizontalSNudge);
    padding-top: 0;

}

.breadcrumbs-wrapper {
    padding-top: var(--spacingVerticalXXXL);
    padding-bottom: var(--spacingVerticalL);
}

.edit-exam {

    & .input-form {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 20px;

        &-item {
            display: flex;
            width: 100%;
            margin-bottom: 10px;

            //  align-items: center;
            &--without-margin {
                margin: 0;
            }
        }

        &-label {
            font-weight: 600;
            width: 30%;
            text-align: right;
            max-width: 250px;
            padding-right: 22px;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        &-field {
            flex: 1;
            display: flex !important;
            flex-direction: column;
            max-width: 300px;
        }
    }

}


.CodeMirror--h150 .CodeMirror {
    height: 150px;
    border: 1px solid rgb(50, 49, 48);
}

.CodeMirror--h200 .CodeMirror {
    height: 200px;
    border: 1px solid rgb(50, 49, 48);
}