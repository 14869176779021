.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.card {
    flex: 1 1 calc(50% - 40px);
    cursor: pointer;

    .card-header {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;

        h3 {
            margin: 0.5em;
        }
    }
}