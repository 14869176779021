.parent-path-link {
    &__trigger {
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        font-size: var(--fontSizeBase300);
        line-height: var(--lineHeightBase300);
        color: var(--colorNeutralForeground2);
        text-decoration: none;

        padding: var(--spacingVerticalXS) var(--spacingHorizontalXS);

        &:hover {
            background-color: var(--colorNeutralBackground3Hover);
        }
    }

    &__icon {
        margin-right: var(--spacingHorizontalXS);
    }
}

.breadcrumbs {
    &--parent-path-link {
        white-space: nowrap;
        font-size: var(--fontSizeBase300);
        line-height: var(--lineHeightBase300);

        & .breadcrumbs__item {
            white-space: nowrap;        
            padding: var(--spacingVerticalXXS) var(--spacingHorizontalXS);
        }

        & .breadcrumbs__item-icon {
            margin-top: var(--spacingVerticalXXS);
        }

        & .breadcrumbs__chevron {
            margin-top: var(--spacingVerticalXXS);
        }
    }
}