.application-details {

    & .input-form {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 20px;
        margin-top: 20px;

        &-item {
            display: flex;
            width: 100%;
            margin-bottom: 15px;
        }
    }


    & .input-label {
        font-weight: 600;
        width: 30%;
        text-align: right;
        max-width: 250px;
        padding-right: 22px;

        &-required {
            padding-right: 0;
        }

    }

    & .input-field {
        display: flex;
        flex-direction: column;
        width: 60%;
        max-width: 350px;

        &-small {
            width: 60%;
            max-width: 350px;
        }

        &-text-area {
            &>span>textarea {
                cursor: default;
            }
        }
    }



}