$sidebarWidth: 260px;

body {
    /* 
     * Values below must be hardcoded so they can be aplied to popup menus.
     * Menus are added to DOM outside of #root element to which FluentProvider is applied
     */

    font-size: 14px; // fontSizeBase300
    font-family: 'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif; // fontFamilyBase
    color: #424242; // colorNeutralForeground2
}

.wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.hover-background {
    &:hover {
        background-color: var(--colorNeutralBackground5Hover);
    }
}

.top-bar {
    background-color: var(--colorNeutralBackground5);
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border-bottom: 1px solid var(--colorNeutralStroke1);

    &__right {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }

    &__left {
        margin-right: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: var(--spacingVerticalXS);
        margin-bottom: var(--spacingVerticalXS);
    }

    &__logo-wrapper {
        text-decoration: none;
        height: 100%;
    }

    &__logo {
        height: 100%;
        max-width: 120px;
    }

    &__menu {
        display: flex;
        flex-direction: row;
        margin-top: 0px;
        margin-bottom: 0px;

        &-item {
            display: flex;
            align-items: center;
            padding: 0 var(--spacingHorizontalS);
            text-decoration: none;
            color: var(--colorNeutralForeground1);
            height: 100%;

            &-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:hover {
                    background-color: var(--colorNeutralBackground5Hover);
                }

                &--user-menu {
                    padding: 0 var(--spacingHorizontalS);
                    margin-left: var(--spacingHorizontalMNudge);
                }
            }
        }
    }
}

.body {
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: var(--colorNeutralBackground2);

    &__content {
        flex: 1;
        width: calc(100vw - $sidebarWidth);
    }
}

.sidebar {
    background-color: #3EB489;
    width: $sidebarWidth;
    display: flex;
    flex-direction: column;
    position: relative;

    &--schrinked {
        width: 60px; // 12 + 4 + 28 + 4 + 12
    }

    &__expand-button {
        cursor: pointer;
        color: var(--colorNeutralBackground5);
        position: absolute;
        top: var(--spacingHorizontalS);
        right: var(--spacingVerticalS);
    }
}

.footer {
    display: none;
}

.page-title {
    font-size: var(--fontSizeBase500);
    line-height: var(--lineHeightBase500);
    font-weight: var(--fontWeightRegular);

    padding-top: var(--spacingVerticalXXXL);
    padding-bottom: var(--spacingVerticalL);
}

.menu-item-readonly {
    cursor: default !important;
    color: var(--colorNeutralForeground2) !important;
    background-color: var(--colorNeutralBackground1) !important;

    :hover {
        background-color: var(--colorNeutralBackground1) !important;
    }
}

.menu-item-with-img>span {
    display: flex;
    align-items: center;

}