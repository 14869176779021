.thumbnail-spinner-wrapper {
    width: 100%;
    height: 170px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.thumbnail-image {
   // width: 100%; 
    cursor: pointer;
}

.thumbnail-image-404 {
  //  width: 100%;
    cursor: pointer;
}