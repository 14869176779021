
  .input-param-form {
    display: grid;
    grid-template-columns: [labels] auto [controls] 1fr;
    grid-auto-flow: row;
    grid-gap: .8em;   
    padding: 1.2em;
    column-gap: 50px;
  }
  .input-param-form > .label  {
    grid-column: labels;
    grid-row: auto;
    align-self: center;
    justify-self: flex-end;
  }
  .input-param-form > .input-field
   {
    grid-column: controls;
    grid-row: auto;
    border: none;   
    max-width: 250px;
  }
