.selected-groups {
    margin-top: 5px;
    margin-bottom: 5px;

    .group-container {
        background-color: var(--colorNeutralBackground2);
        border-radius: 15px;
        outline: transparent;
        position: relative;
        display: inline-flex;
        align-items: center;
        background: rgb(243, 242, 241);
        margin: 1px 2px;
        cursor: default;
        max-width: 300px;
        vertical-align: middle;
        min-width: 0px;
        user-select: none;

        & .group-avatar {
            font-size: 10px;
            font-weight: 600;
            line-height: 24px;
            height: 24px;
            border-radius: 50%;
        }

        & .group-name {
            padding: 0px 8px;
            min-width: 0px;
            width: 100%;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }
    }

}

.group-display-avatar {
    font-size: 10px;
    font-weight: 600;
    line-height: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
}

.group-display-name {
    display: block;
    width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.group-principal-name {
    display: block;
    width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}